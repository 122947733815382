<template>
  <div>
   
    <div class="pageTop">
      <div class="return" @click="returnPage">
        <img src="@/assets/return.png" alt="" />
      </div>
      <div class="title">维护记录详情</div>
      <div class="code"></div>
    </div>
    <!-- <van-cell
      title="项目名称"
      :value="list.equipmentInfo.equipment.projectName"
    /> -->
    <div v-if="!status" >
    <van-cell style="font-size:16px" title="小区名称" :value="list.communityName" />
    <van-cell style="font-size:16px" title="设备名称" :value="list.name" />
    <van-cell style="font-size:16px" title="SN码" :value="list.code" />
    <van-cell style="font-size:16px" title="上次维保时间" :value="list.lastTime" />
    <van-cell style="font-size:16px" title="预计下次维保时间" :value="list.repairDate" />
    <!-- <van-cell title="维护周期" :value="list.equipmentInfo.equipment.repairDay + '天'" /> -->
    <van-cell style="font-size:16px" title="维保保养区域" :value="list.repairArea" />
    <van-cell style="font-size:16px" title="状态" :value="list.status" />
    <van-cell style="font-size:16px" title="故障描述" :value="list.desr" />
    <van-cell style="font-size:16px" title="是否处理" :value="list.isHandle" />
    <van-cell style="font-size:16px" title="是否保修" :value="list.isRepair" />
    <van-cell style="font-size:16px" title="维护人员" :value="list.userName" />
    <div class="title">维修前照片</div>
    <div class="imgList" v-if="imgOld.length > 0">
      <img
        v-for="(item, index) in imgOld"
        :key="index"
        :src="'https://rbxf.baoyj.net/prod-api/' + item.url"
        alt=""
      />
      <!-- <img
        v-for="(item, index) in imgOld"
        :key="index"
        :src="'http://192.168.110.6:8443/' + item.url"
        alt=""
      /> -->
    </div>
    <div class="title" v-else>
      <van-tag type="primary">未上传</van-tag>
    </div>
    <div class="title">维修后照片</div>
    <div class="imgList" style="margin-bottom: 20px" v-if="imgNew.length > 0">
      <img
        v-for="(item, index) in imgNew"
        :key="index"
        :src="'https://rbxf.baoyj.net/prod-api/' + item.url"
        alt=""
      />
      <!-- <img
        v-for="(item, index) in imgNew"
        :key="index"
        :src="'http://192.168.110.6:8443/' + item.url"
        alt=""
      /> -->
    </div>
    <div class="title" v-else>
      <van-tag type="primary">未上传</van-tag>
    </div>
    <div class="bottomBox">
      <el-button
        type="primary"
        style="width: 90%;background: #ed3e3e;border:none"
        @click="reportAgain(list.code)"
        round
        v-if="list.repairStatus === 1"
        >再次填报</el-button
      >
    </div>
  </div>
    <div class="notData" style="pointer-events: none" v-if="status">
      <img  src="@/assets/noData.png" alt="">
    </div>
  </div>
</template>
    
<script>
import { detailInfoApi } from "@/api/login";
export default {
  data() {
    return {
      finished: true,
      loading: true,
      list: {
        equipmentInfo: {
          equipment: {
            os: {
              name: null,
            },
          },
        },
        xfUser: {
          name: null,
        },
      },
      id: null,
      imgOld: [],
      imgNew: [],
      status:false
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    if (this.id == undefined && this.id == null) {
      this.$router.push("/");
    } else {
      this.getDetailData();
    }
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    getDetailData() {
      let data = {
        id: this.id,
      };
      detailInfoApi(data).then((response) => {
        if (response.code == 20000) {
          this.status = false
          this.list = response.data;
          this.list.lastTime = this.list.lastTime.substring(0, 10);
          this.list.repairDate = this.list.repairDate.substring(0, 10);
          this.list.status = this.list.status == 1 ? "正常" : "故障";
          this.list.isHandle = this.list.isHandle == 1 ? "已处理" : "未处理";
          this.list.isRepair = this.list.isRepair == 1 ? "已报修" : "未报修";
          if (this.list.images.length > 0) {
            this.list.images.forEach((item) => {
              if (item.linkType == 1) {
                this.imgOld.push(item);
              } else if (item.linkType == 2) {
                this.imgNew.push(item);
              }
            });
          }
        } else {
          this.status = true
        }
        console.log(this.list);
      });
    },
    reportAgain(code) {
      this.$router.push({
        path: "/setInfo",
        query: {
          code,
        },
      });
    },
  },
};
</script>
    
<style lang="scss" scoped>
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 20px;
    width: 20px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.line {
  height: 7px;
  background: rgb(244, 244, 244);
}
.van-cell {
  padding-top: 20px;
  padding-bottom: 20px;
  span {
    display: inline-block;
    text-align: left;
    word-break: break-all;
  }
  // height: 70px;
}
::v-deep .van-cell__title {
  display: flex;
  align-items: center;
}
::v-deep .van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.maintenance {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 40px;
}

.title {
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.imgList {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  img {
    width: 150px;
    height: 150px;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: rgb(225, 225, 225);
}

.bottomBox {

  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.notData {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    height: 200px;
  }
}
</style>