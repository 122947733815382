<template>
  <div class="home">
    <div
      style="
        font-size: 30px;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img style="width: 50%" src="../assets/logo.png" alt="" />
    </div>
    <el-form
      :model="form"
      ref="loginForm"
      size="small"
      :rules="rules"
      style="
        padding: 50px 25px 50px 25px;
        box-sizing: border-box;
        background: white;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
      "
    >
      <el-form-item prop="username" style="margin-bottom: 12px">
        <el-input
          v-model="form.username"
          placeholder="请输入账号"
          clearable
          size="small"
          @keyup.enter.native="login"
        />
      </el-form-item>
      <el-form-item prop="password" style="margin-bottom: 12px">
        <el-input
          v-model="form.password"
          placeholder="请输入密码"
          type="password"
          clearable
          size="small"
          @keyup.enter.native="login"
        />
      </el-form-item>
      <el-form-item prop="code" style="margin-bottom: 12px">
        <el-input
          v-model="form.code"
          placeholder="请输入验证码"
          clearable
          size="small"
          @keyup.enter.native="login"
        >
          <template slot="append">
            <img
              @click="getVerificationCode"
              style="width: 100px; border-radius: 50px"
              :src="'data:image/png;base64,' + imgCodeObj.img"
              alt=""
            />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="
            background: rgb(166, 33, 36);
            border-color: rgb(166, 33, 36);
            font-size: 25px;
            letter-spacing: 15px;
            text-indent: 15px;
          "
          @click="login()"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { loginApi, getVerificationCode } from "../api/login";
import { setToken, getToken } from "@/utils/auth";
export default {
  name: "HomeView",
  data() {
    return {
      form: {
        username: null,
        password: null,
        uuid: null,
        code: null,
      },
      imgCodeObj: {
        uuid: "",
        img: "",
      },
      verificationCode: "",
    };
  },
  mounted() {
    this.getVerificationCode();
  },
  methods: {
    jump() {
      this.$router.push("basicInfo");
    },
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.form.uuid = this.imgCodeObj.uuid;
          loginApi(this.form).then((response) => {
            if (response.code == 20000) {
              setToken(response.data.token);
              this.$router.push("/");
            }
          });
        }
      });
    },
    getVerificationCode() {
      getVerificationCode().then((res) => {
        if (res.code === 20000) {
          this.imgCodeObj.uuid = res.data.uuid;
          this.imgCodeObj.img = res.data.img;
        }
      });
    },
    test() {
      console.log(getToken());
    },
  },
  computed: {
    //表单验证
    rules() {
      return {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code:[{ required: true, message: "请输入验证码", trigger: "blur" }]
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 40px 0 40px;
  box-sizing: border-box;

  margin: 0px;
  background-image: url(../assets/loginbg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
}
::v-deep .el-input__inner {
  height: 50px !important;
  width: 300px !important;
  margin: 5px 0;
  border-radius: 50px !important;
  ::v-deep .el-input__wrapper {
    border-radius: 50% !important;
  }
}
::v-deep .el-form-item__error {
  font-size: 15px;
}

::v-deep .el-input-group__append {
  background-color: #fff;
  position: absolute;
  border: none;
  padding: 0px;
  right: 108px;
  top: 12px;
}

.el-button {
  height: 50px;
  width: 100%;
  border-radius: 40px !important;
  margin: 10px 0;
}
</style>