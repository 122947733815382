<template>
  <div class="record">
    <div class="pageTop">
      <div class="return"></div>
      <div class="title" @click="testcode">维护记录</div>
      <div class="code">
        <!-- <img src="@/assets/code.png" alt="" /> -->
      </div>
    </div>

    <div class="line"></div>
    <div class="search">
      <!-- <div class="code" @click="searchCode()">
        <img src="@/assets/code.png" alt="" />
      </div> -->
      <van-search
        style="width: 100%"
        v-model="queryForm.identity"
        placeholder="请输入SN码"
        input-align="center"
        clearable
        @search="onSearch"
        @clear="onClear"
      />
    </div>

    <van-list
      v-if="total !=0"
      class="vanList"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="100"
    >
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :value="item.xfUser.name"
        is-link
        :label="item.createTime"
        @click="jump(item.id)"
      >
        <template #title>
          <div>
            <span class="custom-title">{{
              item.equipmentInfo.equipment.os.name
            }}</span>
            <br />
            <van-tag type="primary" plain style="height: 15px">{{
              item.equipmentInfo.identity
            }}</van-tag>
          </div>
        </template>
      </van-cell>
    </van-list>
    <van-list
      v-else
      class="vanList"
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
      :offset="100"
    >
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :value="item.xfUser.name"
        is-link
        :label="item.createTime"
        @click="jump(item.id)"
      >
        <template #title>
          <div>
            <span class="custom-title">{{
              item.equipmentInfo.equipment.projectName
            }}</span>
            <br />
            <van-tag type="primary" plain style="height: 15px">{{
              item.equipmentInfo.identity
            }}</van-tag>
          </div>
        </template>
      </van-cell>
    </van-list>

    <div class="notData" style="pointer-events: none" v-if="total == 0">
      <img  src="../../assets/noData.png" alt="">
    </div>
    
    <div class="bottom">
      <div class="but" @click="scanCode()">
        <img src="@/assets/code.png" alt="" />
        扫码
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getListApi } from "@/api/login";
export default {
  data() {
    return {
      finished: false,
      loading: false,
      list: [],
      queryForm: {
        limit: 14,
        page: 1,
        identity: null,
      },
      total: 0,

      testdata: []
    };
  },
  mounted() {
    // this.getList();
  },
  created() {
    if (
      window.sessionStorage.getItem("searchCode") != undefined &&
      window.sessionStorage.getItem("searchCode") != "" &&
      window.sessionStorage.getItem("searchCode") != null
    ) {
      this.queryForm.identity = window.sessionStorage.getItem("searchCode");
    }
  },
  methods: {
    onClear() {
      this.queryForm = {
        limit: 14,
        page: 1,
        identity: null,
      };
      window.sessionStorage.setItem("searchCode", "");
      this.getList();
    },
    onSearch() {
      this.getList();
    },
    testcode() {
      window.sessionStorage.setItem("code", "OS230800000033");
      this.$router.push({
        name: "basicInfo",
      });
    },
    searchCode() {
      this.$router.push({
        name: "searchCode",
      });
    },
    scanCode() {
      // window.sessionStorage.setItem('code', 'OS230800000001')
      this.$router.push({
        name: "codeExplain",
      });
    },
    jump(id) {
      window.sessionStorage.setItem("maintenanceDetailId", id);
      this.$router.push({
        name: "maintenanceDetail",
      });
    },
    async getList() {
      await getListApi(this.queryForm).then((response) => {
        if (response.code == 20000) {
          this.list = response.data.records;
          this.total = response.data.total;
        }
      });
    },
    async onLoad() {
      await getListApi(this.queryForm).then((response) => {
        if (response.code == 20000) {
          response.data.records.forEach((item) => {
            this.list.push(item);
          });
          this.total = response.data.total;
        }
      });

      this.queryForm.page++;

      this.loading = false;

      if (this.list.length >= this.total) {
        this.finished = true;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.record {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 30px;
    width: 30px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.line {
  height: 7px;
  background: rgb(244, 244, 244);
}
.vanList {
  height: 80%;
  overflow: auto;
  .van-cell {
    height: 100px;
    border-bottom: 1px solid rgb(125, 125, 125);
  }
}

.maintenance {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 40px;
}

.search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.bottom {
  display: flex;
  height: 10%;
  align-items: center;
  justify-content: center;
}
.but {
  background: #ed3e3e;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  border-radius: 10px;
  color: white;
  font-size: 23px;
  letter-spacing: 10px;
  img {
    height: 70%;
    margin-right: 20px;
  }
}
.notData {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    height: 200px;
  }
}
</style>