import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        var token = getToken()
        if (token) {
            config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
            config.headers['Content-Type'] = 'application/json;charset=utf-8'
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data

        if (res.code == 9999) {
            removeToken()
            Message({
                message: res.msg || 'error',
                type: 'error',
                duration: 5 * 1000
            })
            router.replace({
                path: '/',
            })
            return res
        }
        if (res.code != 20000) {
            Message({
                message: res.msg || 'error',
                type: 'error',
                duration: 2 * 1000
            })
            return res
        }
        return res
    }
)

export default service