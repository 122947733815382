import router from './router'
import { getToken } from '@/utils/auth' // get token from cookie


router.beforeEach(async(to, from, next) => {
    const hasToken = getToken()

    if (to.path == '/login') {
        if (hasToken) {
            console.log('登录');
            next('/')
        } else {
            next()
        }
    } else {
        if (hasToken) {
            next()
        } else {
            next('/login')
        }
    }

})

router.afterEach(() => {

})