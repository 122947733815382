<template>
  <div>
    <div class="pageTop">
      <div class="return" @click="returnPage">
        <img src="@/assets/return.png" alt="" />
      </div>
      <div class="title">维护列表</div>
      <div class="code"></div>
    </div>
    <div>
      <div class="list" v-for="(item,index) in list" :key="index">
        <div class="vanList1">
          <!-- <span class="vanList1-l" >分组名称</span> -->
          <span class="vanList1-l" style="flex: 30%">设备名称</span>
          <span class="vanList1-r"
            >{{item.name}}</span
          >
        </div>

        <div class="vanList1">
          <span class="vanList1-l" style="flex: 30%">地址</span>
          <span class="vanList1-r"
            >{{item.local}}</span
          >
        </div>

        <van-button
          type="default"
          class="maintenanceBtn"
          style="background-color: #ed3e3e"
          @click="jumpFilling(item.id)"
          >开始维护</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getMaintenanceList } from "@/api/login";
export default {
  data() {
    return {
      list:[]
    };
  },
  mounted() {
    let setInfoId = this.$route.query.id;
    this.getMaintenanceList(setInfoId);
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    getMaintenanceList(id) {
      let data = {
        id,
      };
      getMaintenanceList(data).then((res) => {
        this.list = res.data
        console.log(res);
      });
    },
    jumpFilling(id) {
      this.$router.push({
          path: '/setInfo',
          query: {
          id
        }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 20px;
    width: 20px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}

.list {
  padding: 10px;
  border-bottom: 1px #e5e5e6 solid;
  .vanList1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.6667vw;
    .vanList1-l {
      font-size: 16px;
      flex: 30%;
      margin-right: 10vw;
    }
    .vanList1-r {
      font-size: 16px;
      color: #b0b0b0;
    }
  }
}
.maintenanceBtn {
  color: #fff;
  font-size: 16px;
  height: 35px;
  border-radius: 50px;
  margin-left: 69vw;
  // position: absolute;
  // right: 0;
}
</style>