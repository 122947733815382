import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import BasicInfo from '../views/device/basicInfo.vue'
import Maintenance from '../views/maintenance/index.vue'
import MaintenanceDetail from '../views/maintenance/detail/index.vue'
import maintenanceTask from '../views/maintenanceTask/maintenanceTask.vue'
import maintenanceList from '../views/maintenanceList/maintenanceList.vue'
import blankCode from '../views/blankCode/blankCode.vue'
import devices from '../views/devices/devices'
Vue.use(VueRouter)

const routes = [{
        title: '维护任务',
        path: '/',
        name: 'maintenanceTask',
        component: maintenanceTask
    },
    {
        title: '地图',
        name: 'map',
        path: '/map',
        component: () =>
            import ('@/views/map/map')
    },
    // {
    //     title: '首页',
    //     path: '/',
    //     name: 'maintenance',
    //     component: Maintenance
    // },
    {
        title: '登录页',
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        title: '基础信息',
        path: '/basicInfo',
        name: 'basicInfo',
        component: BasicInfo
    },
    {
        title: '维护记录详情',
        path: '/maintenanceDetail',
        name: 'maintenanceDetail',
        component: MaintenanceDetail
    },
    {
        title: '设备详情',
        path: '/devices',
        name: 'devices',
        component: devices
    },
    {
        title: '设备绑定',
        path: '/blankCode',
        name: 'blankCode',
        component: blankCode
    },

    {
        title: '维护列表',
        path: '/maintenanceList',
        name: 'maintenanceList',
        component: maintenanceList
    },
    {
        title: '维护记录录入',
        name: 'setInfo',
        path: '/setInfo',
        component: () =>
            import ('@/views/device/components/setInfo')
    },
    {
        title: '二维码扫描',
        name: 'codeExplain',
        path: '/codeExplain',
        component: () =>
            import ('@/views/maintenance/code/codeExplain')
    },
    {
        title: '搜索扫描',
        name: 'searchCode',
        path: '/searchCode',
        component: () =>
            import ('@/views/maintenance/searchCode/index')
    }
]

const router = new VueRouter({
    routes
})

export default router