<template>
  <div>
    <div class="pageTop">
      <div class="return" @click="returnPage">
        <img src="@/assets/return.png" alt="">
      </div>
      <div class="title">基本信息</div>
      <div class="code"></div>
    </div>

    <van-cell-group>
      <!-- <van-cell title="项目名称" :value="infoData.equipment.projectName" /> -->
      <!-- <van-cell title="项目地址" :value="infoData.equipment.projectAddr" /> -->
      <van-cell title="小区名称" :value="infoData.communityName" />
      <van-cell title="楼栋" :value="infoData.buildingName" />
      <van-cell title="楼层" :value="infoData.floorName" />
      <van-cell title="设备分类" :value="infoData.osName" />
      <van-cell title="设备名称" :value="infoData.name" />
      <van-cell title="上次维保时间" :value="infoData.lastTime" />
      <van-cell title="预计下次维保时间" :value="infoData.lastTime" />
      <van-cell title="SN码" :value="infoData.code" />
    </van-cell-group>

    <div v-if="infoData.repairStatus === 1" class="maintenance" @click="jump(infoData.code)">
      <img
        style="width: 30px; height: 30px; margin-right: 5px"
        src="@/assets/maintenance.png"
        alt=""
      />
      <div  style="display: flex; align-items: center">维修填报</div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { infoApi,getDeviceData } from "../../api/login";
export default {
  data() {
    return {
        infoData: {
            equipment: {
                projectName: null,
                projectAddr: null,
                osName: null,
                content: null,
                os: {
                  name: null
                },
            },

            identity: null,
            lastTime: null
        },
        code: null
    }
  },
  created() {
    // this.code = window.sessionStorage.getItem('code')
    // if (this.code != undefined) {
    //     this.getInfo()
    // } else {
    //     Notify({ type: 'warning', message: '未获取到设备ID', duration: 1000 })
    //     this.$router.push('/')
    // }
    this.code = this.$route.query.id
    this.getInfo()
    console.log(this.code);
  },
  methods: {
    returnPage() {
      this.$router.push('/')
    },
    jump(code) {
      this.$router.push({
        path: "/setInfo",
        query: {
          code,
        },
      });
    },
    getInfo() {
      let data = {
        code: this.code
      }
      getDeviceData(data).then((response) => {
        if (response.code == 20000) {
            this.infoData = response.data
            this.infoData.lastTime ? this.infoData.lastTime = this.infoData.lastTime.substring(0, 10) : ""
            this.infoData.repairDate ? this.infoData.repairDate = this.infoData.repairDate.substring(0, 10) : ""
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 20px;
    width: 20px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.line {
  height: 7px;
  background: rgb(244, 244, 244);
}
.van-cell {
  height: 70px;
}
::v-deep .van-cell__title {
  display: flex;
  align-items: center;
}

::v-deep .van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.maintenance {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 40px;
}
</style>