<template>
  <div>
    <div class="pageTop">
      <div class="return" @click="returnPage">
        <img src="@/assets/return.png" alt="" />
      </div>
      <div class="title">设备绑定</div>
      <div class="code"></div>
    </div>
    <div>
      <van-field
        class="spstyles"
        readonly
        clickable
        v-model="blankCode"
        name="二维码种类"
        label="二维码种类"
        is-link
        :rules="[{ required: true, message: '请选择二维码种类' }]"
        input-align="right"
        @click="statePicker = true"
      />
      <van-field
        v-if="communityStatus"
        class="spstyles"
        readonly
        clickable
        v-model="communityName"
        name="小区"
        label="小区名称"
        is-link
        :rules="[{ required: true, message: '请选择小区名称' }]"
        input-align="right"
        @click="communityPicker = true"
      />
      <van-field
        v-if="buildingStatus"
        class="spstyles"
        readonly
        clickable
        v-model="buildingName"
        name="楼栋"
        label="楼栋"
        is-link
        :rules="[{ required: true, message: '请选择楼栋' }]"
        input-align="right"
        @click="buildPicker = true"
      />
      <van-field
        v-if="floorStatus"
        class="spstyles"
        readonly
        clickable
        v-model="floorName"
        name="楼层"
        label="楼层"
        is-link
        :rules="[{ required: true, message: '请选择楼层' }]"
        input-align="right"
        @click="floorPicker = true"
      />
      <!-- 设备名称 -->
      <van-field
        v-if="deviceStatus"
        class="spstyles"
        readonly
        clickable
        v-model="deviceName"
        name="设备"
        label="设备"
        is-link
        :rules="[{ required: true, message: '请选择设备' }]"
        input-align="right"
        @click="devicePicker = true"
      />
      <!-- 分组名称 -->
      <van-field
        v-if="groupStatus"
        class="spstyles"
        readonly
        clickable
        v-model="groupName"
        name="分组"
        label="分组"
        is-link
        :rules="[{ required: true, message: '请选择分组' }]"
        input-align="right"
        @click="groupPicker = true"
      />
      <van-cell
        style="height: 70px; display: flex; align-items: center"
        title="SN码"
        :value="code"
      />
      <!-- 二维码选择框 -->
      <van-popup v-model="statePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="statePicker = false"
        />
      </van-popup>
      <!-- 小区选择框 -->
      <van-popup v-model="communityPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="residentialQuartersList"
          value-key="name"
          @confirm="communityOnConfirm"
          @cancel="communityPicker = false"
        />
      </van-popup>

      <!-- 楼栋选择框 -->
      <van-popup v-model="buildPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="buildList"
          value-key="building"
          @confirm="buildOnConfirm"
          @cancel="communityPicker = false"
        />
      </van-popup>
      <!-- 楼层选择框 -->
      <van-popup v-model="floorPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="floorList"
          value-key="name"
          @confirm="floorOnConfirm"
          @cancel="floorPicker = false"
        />
      </van-popup>
      <!-- 设备选择框 -->
      <van-popup v-model="devicePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="deviceList"
          value-key="name"
          @confirm="deviceOnConfirm"
          @cancel="devicePicker = false"
        />
      </van-popup>
      <!-- 分组选择框 -->
      <van-popup v-model="groupPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="groupList"
          value-key="groupName"
          @confirm="groupOnConfirm"
          @cancel="groupPicker = false"
        />
      </van-popup>
    </div>
    <div class="bottomBox">
      <el-button type="primary" style="width: 90vw;background: #ed3e3e;border:none" @click="binding()" round
        >确认绑定</el-button
      >
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { Dialog } from "vant";
import {
  getResidentialQuarters,
  getBuild,
  getFloor,
  getDevice,
  getGrouping,
  bindDevice,
} from "@/api/login";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      data: {},
      code: "123456789456",
      bindType: "", // 二维码类型
      blankCode: "", // 二维码名称
      communityName: "", // 小区名称
      buildingName: "", // 楼栋名称
      deviceName: "", // 设备名称
      floorName: "", // 楼层名称
      groupName: "", // 分组名称
      statePicker: false, // 二维码选择框
      communityPicker: false, // 小区选择框
      buildPicker: false, // 楼栋选择框状态
      floorPicker: false, // 楼栋选择框状态
      devicePicker: false, // 设备选择框状态
      groupPicker: false, // 分组选择框状态
      building: false,
      buildingStatus: false, // 楼层输入框状态
      communityStatus: false, // 小区输入框状态
      floorStatus: false, // 楼层输入框状态
      deviceStatus: false, // 设备输入框状态
      groupStatus: false,
      residentialQuartersList: [], // 小区信息
      buildList: [], // 楼栋信息
      floorList: [], // 楼层信息
      deviceList: [], // 设备信息
      groupList: [], // 分组信息
      idFloor: "",
      idEquipmentInfo: "",
      idGroup: "",
      columns: [
        { text: "楼层码", bindType: 1 },
        { text: "设备码", bindType: 2 },
        { text: "分组码", bindType: 3 },
      ],
      bindingState: false,
    };
  },
  created() {
    this.code = this.$route.query.id;
  },
  mounted() {
    this.getResidentialQuarters();
  },
  methods: {
    binding() {
      let data = {};
      if (this.bindType === 1) {
        if (
          this.communityName === "" ||
          this.buildingName === "" ||
          this.floorName === ""
        ) {
          Notify({ type: "warning", message: "请完善信息" });
          return;
        } else {
          data = {
            code: this.code,
            bindType: 1,
            idFloor: this.idFloor,
          };
        }
      } else if (this.bindType === 2) {
        if (this.communityName === "" || this.deviceName === "") {
          Notify({ type: "warning", message: "请完善信息" });
          return;
        } else {
          data = {
            code: this.code,
            bindType: 2,
            idEquipmentInfo: this.idEquipmentInfo,
          };
        }
      } else if (this.bindType === 3) {
        if (this.communityName === "" || this.groupName === "") {
          Notify({ type: "warning", message: "请完善信息" });
          return;
        } else {
          data = {
            code: this.code,
            bindType: 3,
            idGroup: this.idGroup,
          };
        }
      } else {
        Notify({ type: "warning", message: "请完善信息" });
        return;
      }
      if (this.bindingState) {
        bindDevice(data).then((res) => {
          if (res.code === 20000) {
            Notify({ type: "success", message: "绑定成功", duration: 1000 });
            this.$router.replace("/");
          } else {
            Notify({ type: "warning", message: "绑定失败，请检查信息！" });
          }
        });
      } else {
        this.$dialog
          .confirm({
            message: "是否替换绑定二维码",
            confirmButtonColor: "red",
          })
          .then(() => {
            bindDevice(data).then((res) => {
              if (res.code === 20000) {
                Notify({
                  type: "success",
                  message: "绑定成功",
                  duration: 1000,
                });
                this.$router.replace("/");
              } else {
                Notify({ type: "warning", message: "绑定失败，请检查信息！" });
              }
            });
          })
          .catch(() => {});
      }
      console.log(this.bindingState, data);
    },
    getResidentialQuarters() {
      getResidentialQuarters().then((res) => {
        if (res.code === 20000) {
          this.residentialQuartersList = res.data;
        }
      });
    },

    reset(id) {
      this.bindType = id;
      this.communityName = "";
      this.buildingStatus = false;
      this.floorStatus = false;
      this.deviceStatus = false;
      this.groupStatus = false;
      this.bindingState = false;
    },
    // 二维码确认
    onConfirm(val) {
      console.log(val);
      if (val.bindType === 1) {
        this.reset(1);
      } else if (val.bindType === 2) {
        this.reset(2);
      } else if (val.bindType === 3) {
        this.reset(3);
      }
      this.blankCode = val.text;
      this.communityStatus = true;
      this.statePicker = false;
    },
    // 小区确认
    communityOnConfirm(val) {
      this.communityName = val.name;
      this.communityPicker = false;
      let data = {
        idCommunity: val.id,
      };
      if (this.bindType === 1) {
        this.buildingName = "";
        this.floorName = "";
        getBuild(data).then((res) => {
          console.log(res);
          if (res.code === 20000) {
            this.buildList = res.data;
            this.buildingStatus = true;
          }
        });
      } else if (this.bindType === 2) {
        this.deviceName = "";
        getDevice(data).then((res) => {
          if (res.code === 20000) {
            this.deviceList = res.data;
            this.deviceStatus = true;
          }
        });
      } else if (this.bindType === 3) {
        this.groupName = "";
        getGrouping(data).then((res) => {
          if (res.code === 20000) {
            this.groupList = res.data;
            this.groupStatus = true;
          }
        });
      }
      console.log(val);
    },
    // 楼栋确认
    buildOnConfirm(val) {
      this.buildingName = val.building;
      this.buildPicker = false;
      let data = {
        idBuilding: val.id,
      };
      getFloor(data).then((res) => {
        if (res.code === 20000) {
          this.floorList = res.data;
          this.floorStatus = true;
        }
        console.log(res);
      });
    },
    floorOnConfirm(val) {
      if (!val.code) {
        this.bindingState = true;
      }
      console.log(val);
      this.idFloor = val.id;
      this.floorName = val.name;
      this.floorPicker = false;
    },
    deviceOnConfirm(val) {
      if (!val.code) {
        this.bindingState = true;
      }
      this.idEquipmentInfo = val.id;
      this.deviceName = val.name;
      this.devicePicker = false;
    },
    groupOnConfirm(val) {
      if (!val.code) {
        this.bindingState = true;
      }
      this.idGroup = val.id;
      this.groupName = val.groupName;
      this.groupPicker = false;
    },
    returnPage() {
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 20px;
    width: 20px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.spstyles {
  height: 70px;
  display: flex;
  align-items: center;
  .van-cell {
    height: 70px;
  }
  ::v-deep .van-cell__title {
    display: flex;
    align-items: center;
  }
  ::v-deep .van-cell__value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.bottomBox {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
</style>