<template>
  <div class="record">
    <div class="pageTop">
      <div class="return"></div>
      <div class="title">{{ title }}</div>
      <div class="code">
        <!-- <img src="@/assets/code.png" alt="" /> -->
      </div>
    </div>
    <div class="search" v-if="taskState">
      <!-- <div class="code" @click="searchCode()">
        <img src="@/assets/code.png" alt="" />
      </div> -->
      <van-search
        class="concreteSearch"
        v-model="queryForm.name"
        placeholder=""
        clearable
        @search="onSearch"
        @clear="onClear"
      />
      <span @click="onSearch">搜索</span>
    </div>

    <van-list
      v-if="taskState"
      class="vanList"
      v-model="taskLoading"
      :finished="taskFinished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="loadMore"
    >
      <van-cell v-for="(item, index) in taskLists" :key="index">
        <template #title>
          <div>
            <div class="vanList1">
              <span class="vanList1-l" style="flex: 55%" v-if="item.type === 2"
                >分组名称</span
              >
              <span class="vanList1-l" style="flex: 55%" v-if="item.type === 1"
                >设备名称</span
              >
              <!-- <span>设备名称</span> -->
              <span class="vanList1-r">{{ item.name }}</span>
            </div>
            <div class="vanList1">
              <span class="vanList1-l" style="flex: 55%">维护日期</span>
              <span class="vanList1-r" v-if="item.recently_time !== null">
                {{ item.recently_time.substring(0, 10) }}
              </span>
              <span class="vanList1-r" v-else> </span>
            </div>
            <div class="vanList1">
              <span class="vanList1-l">地址</span>
              <span class="vanList1-r">{{ item.local }}</span>
            </div>
            <div class="vanList1">
              <span class="vanList1-l">描述</span>
              <span class="vanList1-r">{{ item.remark }}</span>
            </div>
            <van-button
              v-if="item.type === 2"
              type="default"
              class="maintenanceBtn"
              style="background-color: #feae1b"
              @click="jumpMaintenanceList(item.id)"
              >维护列表</van-button
            >
            <van-button
              v-if="item.type === 1"
              type="default"
              class="maintenanceBtn"
              style="background-color: #ed3e3e"
              @click="jumpFilling(item.id)"
              >开始维护</van-button
            >
          </div>
        </template>
      </van-cell>
    </van-list>
    <!-- <van-list
    class="vanList"
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    :offset="100"
    @load="loadMore"
  >
    <van-cell v-for="(item, index) of 4" :key="index">
      <template #title>
        <div>
          <div class="vanList1">
            <span class="vanList1-l" style="flex: 55%;">分组名称</span>
            <span>设备名称</span>
            <span class="vanList1-r">A组</span>
          </div>
         
          <div class="vanList1">
            <span class="vanList1-l">地址</span>
            <span class="vanList1-r"
              >温州市龙湾区兴区路55号温州市龙湾区兴区路55号温州市龙湾区兴区路55号温州市龙湾区兴区路55号</span
            >
          </div>
          
          <van-button type="default" class="maintenanceBtn"
            >开始维护</van-button
          >
        </div>
      </template>
    </van-cell>
  </van-list> -->
    <div class="vanListTop" v-if="recordState">
      <div class="vanListTopTitle">维护时间</div>
      <div class="vanListTopTitle">设备名称</div>
      <div class="vanListTopTitle">维护人员</div>
    </div>
    <van-list
      v-if="recordState"
      class="vanList"
      v-model="recordLoading"
      :finished="recordFinished"
      finished-text="没有更多了"
      :offset="100"
      @load="recordLoadMore"
    >
      <div
        class="maintainRecordInformation"
        v-for="(item, index) in recordLists"
        :key="index"
      >
        <div class="maintainRecordInformationTitle">
          {{ item.createTime.substring(0, 10) }}
        </div>
        <div class="maintainRecordInformationTitle">{{ item.name }}</div>
        <div
          class="maintainRecordInformationTitle"
          style="display: flex; align-items: center"
          @click="jumpDetails(item.id)"
        >
          <span>{{ item.userName }}</span> <span class="arrow"></span>
        </div>
      </div>
    </van-list>
    <div style="height: 13vh; width: 100vw"></div>
    <div class="foot">
      <div class="but" @click="scanCode()">
        <!-- <img src="@/assets/code.png" alt="" /> -->
        扫一扫
      </div>
      <div class="navigation">
        <div
          @click="switchs(1)"
          style="display: flex; align-items: center; margin-right: 10vw"
        >
          <img src="@/assets/maintenance1.png" alt="" />
          <span style="margin-left: 10px">维护任务</span>
        </div>
        <div @click="switchs(2)" style="display: flex; align-items: center">
          <img src="@/assets/maintenance2.png" alt="" />
          <span style="margin-left: 10px">维护记录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { taskList, getMaintenanceRecords } from "@/api/login";
export default {
  data() {
    return {
      queryForm: {
        limit: 5,
        page: 1,
        name: "",
      },
      queryForm2: {
        limit: 15,
        page: 1,
      },
      taskFinished: false,
      taskLoading: false,
      recordLoading: false,
      recordFinished: false,
      title: "维护任务",
      taskState: true,
      recordState: false,
      taskLists: [],
      recordLists: [],
      taskTotal: "",
      recordTotal: "",
    };
  },
  mounted() {
    this.taskList();
    this.getMaintenanceRecords();
  },
  methods: {
    loadMore() {
      this.queryForm.page += 1;
      this.taskList();
      console.log(this.taskLoading);
      console.log("有出发嘛");
    },
    recordLoadMore() {
      this.queryForm2.page += 1;
      this.getMaintenanceRecords();
    },
    async taskList() {
      const res = await taskList(this.queryForm);
      if (res.code === 20000) {
        this.taskLists = [...this.taskLists, ...res.data.value];
        this.taskTotal = res.data.count;
      }
      this.taskLoading = false;
      if (this.queryForm.page * this.queryForm.limit >= this.taskTotal) {
        this.taskFinished = true;
      }
    },
    async getMaintenanceRecords() {
      const res = await getMaintenanceRecords(this.queryForm2);
      if (res.code === 20000) {
        this.recordLists = [...this.recordLists, ...res.data.records];
        this.recordTotal = res.data.total;
      }
      this.recordLoading = false;
      if (this.queryForm2.page * this.queryForm2.limit >= this.recordTotal) {
        this.recordFinished = true;
      }
      console.log(res);
    },
    switchs(type) {
      console.log(type);
      if (type == 1) {
        this.taskState = true;
        this.recordState = false;
        this.title = "维护任务";
        console.log(11, this.taskState, this.recordState);
      } else {
        this.recordState = true;
        this.taskState = false;
        this.title = "维护记录";
        console.log(22, this.taskState, this.recordState);
      }
    },
    onSearch(val) {
      console.log(val);
      this.queryForm.page = 1;
      this.taskFinished = false;
      this.taskLists = [];
      this.taskList();
    },
    onClear() {
      this.queryForm = {
        page: 1,
        limit: 5,
        name: "",
      };
    },
    jumpFilling(id) {
      this.$router.push({
        path: "/setInfo",
        query: {
          id,
        },
      });
    },
    jumpMaintenanceList(id) {
      this.$router.push({
        path: "/maintenanceList",
        query: {
          id,
        },
      });
    },
    jumpDetails(id) {
      this.$router.push({
        path: "/maintenanceDetail",
        query: {
          id,
        },
      });
    },
    scanCode() {
      this.$router.push({
        name: "codeExplain",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .record {
//   // height: 100vh;
//   // display: flex;
//   // flex-direction: column;
// }
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
  }
  .code {
    height: 50px;
    width: 40px;
  }
  .return {
    height: 50px;
    width: 40px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 12px;
  .concreteSearch {
    width: 85%;
    border: 2px #909090 solid;
    border-radius: 50px;
    padding: 0px 12px;
  }
  span {
    margin-right: 10px;
    font-size: 18px;
  }
  // .code {
  //   height: 30px;
  //   width: 30px;
  // }
  // img {
  //   width: inherit;
  //   height: inherit;
  // }
}

::v-deep .van-field__left-icon .van-icon {
  font-size: 27px;
}

::v-deep .van-search__content {
  background-color: #fff;
  padding-left: 0px;
}

.vanList {
  .vanList1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.6667vw;
    .vanList1-l {
      font-size: 16px;
      flex: 30%;
      margin-right: 10vw;
    }
    .vanList1-r {
      font-size: 16px;
      color: #b0b0b0;
      font-weight: 600;
    }
  }
}

.maintenanceBtn {
  color: #fff;
  font-size: 16px;
  height: 35px;
  border-radius: 50px;
  float: right;
}
// .list {
//   height: 70vw;
// }
.foot {
  height: 12vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding-top: 10px;
  border-top: 1px solid #e5e5e6;
  box-sizing: border-box;
  .but {
    background: #ed3e3e;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    border-radius: 50px;
    color: white;
    font-size: 20px;
    letter-spacing: 5px;
    margin: 0 auto;
    img {
      height: 70%;
      margin-right: 5px;
    }
  }
  .navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.vanListTop {
  display: flex;
  padding-bottom: 18px;
  border-bottom: 1px #e5e5e6 solid;
  .vanListTopTitle {
    flex: 0 0 33.3%;
    display: flex;
    justify-content: center;
  }
}

.maintainRecordInformation {
  display: flex;
  align-items: center;
  padding: 18px 0px;
  border-bottom: 1px #e5e5e6 solid;
  .maintainRecordInformationTitle {
    flex: 0 0 33.3%;
    display: flex;
    padding: 0 5px;
    justify-content: center;
    box-sizing: border-box;
  }
}

.maintainRecordList {
  display: flex;

  .maintainRecordInformationC {
    // width: 33vw;
    flex: 0 0 33.3%;
    box-sizing: border-box;
    padding: 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .maintainRecordInformationC-1 {
    padding-bottom: 5px;
    border: 1px solid #f6f6f6;
  }
}

.arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid #c0c0c0;
  border-right: 2px solid #c0c0c0;
  transform: rotate(45deg) translateY(3px);
  margin-left: 10px;
}
</style>