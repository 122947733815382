import request from '../utils/request'
import requestFile from '../utils/requestFORM'
import requestData from '../utils/requestData'
// 登录
export function loginApi(params) {
    return request({
        url: '/login/loginByPass',
        method: 'post',
        params
    })
}

// 二维码
export function getVerificationCode() {
    return request({
        url: '/login/captchaImage',
        method: 'get'
    })
}

// 维修任务
export function taskList(params) {
    return request({
        url: '/maintenance/list',
        method: 'get',
        params
    })
}

// 首页维修记录
export function getMaintenanceRecords(params) {
    return request({
        url: '/repair/list',
        method: 'get',
        params
    })
}

// 维护记录
export function getListApi(params) {
    return request({
        url: '/repair/list',
        method: 'get',
        params
    })
}

// 维护记录
export function getMaintenanceList(params) {
    return request({
        url: '/maintenance/group/list',
        method: 'get',
        params
    })
}

// 维护填报最新记录
export function getupToDate(params) {
    return request({
        url: '/repair/repair/last',
        method: 'get',
        params
    })
}

// 查询二维码种类
export function infoApi(params) {
    return request({
        url: '/repair/info',
        method: 'post',
        params
    })

}

// 获取小区
export function getResidentialQuarters() {
    return request({
        url: '/repair/community',
        method: 'get',
    })
}

// 获取楼栋
export function getBuild(params) {
    return request({
        url: '/repair/build',
        method: 'get',
        params
    })
}

// 获取楼层
export function getFloor(params) {
    return request({
        url: '/repair/floor',
        method: 'get',
        params
    })
}

// 获取设备
export function getDevice(params) {
    return request({
        url: '/repair/equipment',
        method: 'get',
        params
    })
}

// 获取分组
export function getGrouping(params) {
    return request({
        url: '/repair/group',
        method: 'get',
        params
    })
}

// 绑定设备
export function bindDevice(params) {
    return request({
        url: '/repair/band/ewm',
        method: 'POST',
        params
    })
}

// 获取楼层设备
export function getFloorDevices(params) {
    return request({
        url: '/repair/xffloor',
        method: 'GET',
        params
    })
}

// 获取分组设备
export function getGroupingDevices(params) {
    return request({
        url: '/repair/getgroup',
        method: 'GET',
        params
    })
}


// 通过code查询设备id
export function getDeviceId(params) {
    return request({
        url: '/repair/infoIdByCode',
        method: 'GET',
        params
    })
}

// 通过code查询设备信息
export function getDeviceData(params) {
    return request({
        url: '/repair/equipment/info',
        method: 'GET',
        params
    })
}

// 图片上传
export function imgUploadApi(data) {
    return requestFile({
        url: '/file/image/common/maintenance',
        method: 'post',
        data: {
            file: data.file
        }
    })
}

// 添加维修记录
export function addRepairApi(data) {
    return requestData({
        url: '/repair/addRepair',
        method: 'post',
        data
    })
}

// 维护记录详情
export function detailInfoApi(params) {
    return request({
        url: '/repair/repair/info',
        method: 'get',
        params: {
            id: params.id
        }
    })
}