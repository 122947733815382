import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
import { List,Cell, CellGroup, NavBar, Icon,Swipe, SwipeItem, Form, Field, Picker,Popup,Uploader,Notify, Tag, Search,Button  } from 'vant';
import 'vant/lib/index.css';

Vue.use(ElementUI);
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(SwipeItem)
Vue.use(Swipe)
Vue.use(Form)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(Notify)
Vue.use(Tag)
Vue.use(Search)
Vue.use(Button)
import '@/permission' // permission control



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
