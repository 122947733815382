<template>
  <div>
    <div class="pageTop">
      <div class="return" @click="returnPage">
        <img src="@/assets/return.png" alt="" />
      </div>
      <div class="title">设备列表</div>
      <div class="code"></div>
    </div>
    <div v-if="!status">
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="vanList1">
          <!-- <span class="vanList1-l" >分组名称</span> -->
          <span class="vanList1-l" style="flex: 30%">设备名称</span>
          <span class="vanList1-r">{{ item.name }}</span>
        </div>

        <div class="vanList1">
          <span class="vanList1-l" style="flex: 30%">地址</span>
          <span class="vanList1-r">{{ item.community.local }}</span>
        </div>

        <van-button
          type="default"
          class="maintenanceBtn"
          style="background-color: #ed3e3e"
          @click="jumpFilling(item.id)"
          >查看详情</van-button
        >
      </div>
    </div>
    <div class="notData" style="pointer-events: none" v-if="status">
      <img src="@/assets/noData.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getFloorDevices, getGroupingDevices } from "@/api/login";

export default {
  data() {
    return {
      list: [],
      status: false,
    };
  },
  mounted() {
    let setInfoId = this.$route.query.id;
    let type = this.$route.query.type;
    console.log(setInfoId, type);
    if (type == 2) {
      this.getFloorDevices(setInfoId);
    } else if (type == 3) {
      this.getGroupingDevices(setInfoId);
    }
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },

    getFloorDevices(code) {
      let data = {
        code,
      };
      getFloorDevices(data).then((res) => {
        if (res.code === 20000) {
          this.list = res.data;
          if (res.data.length <= 0) {
            this.status = true;
          }
        }
      });
    },

    getGroupingDevices(code) {
      let data = {
        code,
      };
      getGroupingDevices(data).then((res) => {
        if (res.code === 20000) {
          this.list = res.data;
          if (res.data.length <= 0) {
            this.status = true;
          }
        }
      });
    },
    jumpFilling(id) {
      this.$router.push({
        path: "/maintenanceDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pageTop {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  .code {
    height: 30px;
    width: 30px;
  }
  .return {
    height: 20px;
    width: 20px;
  }
  img {
    width: inherit;
    height: inherit;
  }
}

.list {
  padding: 10px;
  border-bottom: 1px #e5e5e6 solid;
  .vanList1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.6667vw;
    .vanList1-l {
      font-size: 16px;
      flex: 30%;
      margin-right: 10vw;
    }
    .vanList1-r {
      font-size: 16px;
      color: #b0b0b0;
    }
  }
}
.maintenanceBtn {
  color: #fff;
  font-size: 16px;
  height: 35px;
  border-radius: 50px;
  margin-left: 69vw;
  // position: absolute;
  // right: 0;
}

.notData {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    height: 200px;
  }
}
</style>